import {Component, Input, OnInit} from '@angular/core';
import {ErrorLog} from '../../Entities/ErrorLog';
import {ErrorLogService} from '../../services/error-log.service';
import {ErrorLogFilter} from '../../Entities/ErrorLogFilter';
import {UserService} from '../../services/user.service';
import {User} from '../../Entities/User';

@Component({
  selector: 'app-error-log',
  templateUrl: './error-log.component.html'
})
export class ErrorLogComponent implements OnInit {

  errorLogs: ErrorLog[];
  totalPageNumber: number;
  pageSize = 10;
  allPlatforms: string[];
  allUsers: User[];
  constructor(private errorLogService: ErrorLogService, private userService: UserService) { }

  ngOnInit() {
    this.getAllRecordedPlatforms();
    this.getAllUsersToFilterBy();
  }

  getFilteredErrorLogs(errorLogFilter: ErrorLogFilter)
  {
    errorLogFilter.PageSize = this.pageSize;

    this.errorLogService.getFilteredListOfErrors(errorLogFilter).subscribe( response => {
      this.totalPageNumber = response.m_Item1;
      this.errorLogs = response.m_Item2;
    })
  }

  getAllRecordedPlatforms() {
    this.errorLogService.getAllRecordedPlatforms().subscribe( listOfPlatforms => {
      this.allPlatforms = listOfPlatforms;
    })
  }

  getAllUsersToFilterBy() {
    this.userService.getUsers().subscribe(users => {
      debugger;
      this.allUsers = users;
    })
  }
}
