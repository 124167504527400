import {Component, Inject, Input, OnInit} from '@angular/core';
import {MatSnackBar} from '@angular/material';
import {ActivatedRoute} from '@angular/router';
import {ApprovedAttachment} from '../../Entities/EmailEntities/ApprovedAttachment';
import {ApprovedItem} from '../../Entities/EmailEntities/ApprovedItem';
import {Email} from '../../Entities/EmailEntities/Email';
import {PaginateModel} from '../../Entities/PaginateModel';
import {SearchCriteria} from '../../Entities/SearchCriteria';
import {Theme} from '../../Entities/Theme';
import {ApprovedAttachmentService} from '../../services/approved-attachment.service';
import {ApprovedEmailService} from '../../services/EmailServices/approved-email.service';
import {LoginService} from '../../services/login.service';
import {SubscriptionService} from '../../services/subscription.service';
import {ThemeCriteriaService} from '../../services/theme-criteria.service';
import {ThemeService} from '../../services/theme.service';
import {FileHistory} from '../../Entities/FileHistory';
import {FileHistoryService} from '../../services/file-history.service';
import {GDPRLog} from '../../Entities/GDPRLog';
import {ClickHistory} from '../../Entities/ClickHistory';
import {ClickHistoryService} from '../../services/click-history.service';
import {ThemePaginateModel} from '../../Entities/ThemePaginateModel';
import {CollabItemService} from '../../services/CollabItem/collab-item.service';
import {CollabItemMonthCount} from '../../Entities/CollabItem/CollabItemMonthCount';
import {CollabItemOption} from '../../Entities/CollabItem/CollabItemOption';
import {Observable, Subscription} from 'rxjs';

@Component({
  selector: 'app-email-controller',
  templateUrl: './email-controller.component.html',
  styleUrls: ['./email-controller.component.css']
})
export class EmailControllerComponent implements OnInit {

  pageModel : PaginateModel = new PaginateModel();

  getDataForPageSub : Subscription;
  getFileTypeOptionsSub : Subscription;
  getSenderOptionsSub : Subscription;
  getRecipientOptionsSub : Subscription;

  downloadURL: string
  modalWindowId: string;
  isModal: boolean;
  isRejected: boolean;
  base64Item: string;
  approvedAttachments: ApprovedAttachment[];
  approvedItems: ApprovedItem[];
  years: any[];
  emailsCount: number;
  attachmentCount: number;
  theme: Theme;
  title: string;
  isSubscribed: boolean;
  isAdmin: boolean;
  isLoadingData: boolean;
  view: number;
  fileUrl: string;

  barLabelYear: string[] = ['Januar', 'Februar', 'Marts', 'April', 'Maj', 'Juni', 'Juli', 'August', 'September', 'Oktober', 'November', 'December'];
  barLabelValueYear: number[] = [1,2,3,4,5,6,7,8,9,10,11,12];
  barLabelDynamic: string[] = []; // 'Januar', 'Februar', 'Marts',
  barLabelValueDynamic: number[] = [];

  barChartLabels: string[] = ['Januar', 'Februar', 'Marts', 'April', 'Maj', 'Juni', 'Juli', 'August', 'September', 'Oktober', 'November', 'December'];
  barMonthValue: number[] = []; // 1,2,3,4,5,6
  barChartData: any[] = [
    {
      data: [20, 20, 20, 20, 20, 20, 20, 20, 20, 20, 20, 20, 20],
      label: 'Emails & filer',
      backgroundColor: [
        '#007acc',
        '#007acc',
        '#007acc',
        '#007acc',
        '#007acc',
        '#007acc',
        '#007acc',
        '#007acc',
        '#007acc',
        '#007acc',
        '#007acc',
        '#007acc',
      ],
      optionsVariable: {scales: {yAxes: [{ticks: {min: 0, stepValue: 10, max: 100,}}]}}
    }
  ];
  lastReceivedCartData : CollabItemMonthCount[];

  routeParams: any;
  detailedEmail: Email;
  detailedFile: ApprovedAttachment;
  sortFields: string[] = Object.getOwnPropertyNames(new ApprovedItem());
  pdfToOpenUrl : string;
  fileHistories : FileHistory[];
  returnUrl : string;
  //themeIdFromRoute : number;
  selectedYear? : number = 0;

  // NM 050220 - Add entity to theme >>
  dropDownThemes: Theme[];
  // NM 050220 - Add entity to theme <<

  // NM FilterOptions >>
  availableOptions_FileType : CollabItemOption[];
  availableOptions_Sender : CollabItemOption[];
  availableOptions_Recipiant : CollabItemOption[];
  // NM FilterOptions <<


  constructor(private fileHistoryService: FileHistoryService,
    private emailService: ApprovedEmailService,
    private route: ActivatedRoute,
    private themeService: ThemeService,
    private themeCriteriaService: ThemeCriteriaService,
    private subscribeService: SubscriptionService,
    private attachmentService: ApprovedAttachmentService,
    private fileService: ApprovedAttachmentService,
    private loginService: LoginService,
    private clickHistoryService : ClickHistoryService,
    private snackBar: MatSnackBar,
    private collabService : CollabItemService
  ) {
    this.title;
    this.isLoadingData = false;
    this.routeParams = null;
    this.approvedItems = [];
    this.approvedAttachments = [];



    this.route.params.subscribe(params => {

      this.routeParams = params;
      if (!!this.routeParams['itemId'] && !!this.routeParams['itemType']) {

        let item = new ApprovedItem();
        item.ID = this.routeParams['itemId'];
        let typeNumber: number;
        if (this.routeParams['itemType'] == 'ApprovedFile') {
          typeNumber = 0;
        } else {
          typeNumber = 1;
        }
        item.IsMail = typeNumber;

        this.returnUrl = this.routeParams['returnUrl'];

        this.showDetailsOfApprovedItem(item);
      }
      else if(!!this.routeParams['themeId'])
      {
        //this.themeIdFromRoute = this.routeParams['themeId'];
        this.pageModel.Theme = {ID: this.routeParams['themeId'] };
        this.pageModel.ThemeId = this.routeParams['themeId'];
        console.log("ThemeID: " + this.routeParams['themeId']);
        let loggedInUser = this.loginService.getLoggedInUserID();
        this.themeService.UpdateOrCreateThemeRelation(this.pageModel.ThemeId, parseInt(loggedInUser)).subscribe(() => {
          this.initializePage()
        });

      }
      else{
        this.initializePage()
      }
    });
  }

  ngOnInit() {

    // Sets the dynamic barChar preset variables
    for (let i = 0; i < 12; i++) {

      let d = new Date();
      d.setDate(1);
      let m = d.getMonth() - i;
      //d.setMonth(d.getMonth() - i);
      new Date(d.setMonth(m)); // 231019 NM deleted console log around this line

      this.barLabelDynamic[i] = this.barLabelYear[d.getMonth()];
      this.barLabelValueDynamic[i] = d.getMonth() + 1;

      this.barMonthValue[i] = d.getMonth() + 1;

    }
    this.barLabelDynamic.reverse();
    this.barLabelValueDynamic.reverse();

    this.barMonthValue.reverse();

    //this.initializePage();
  }

  initializePage()
  {
    this.isLoadingData = true;
    this.pageModel.PageSize = 10;
    this.pageModel.PageIndex = 1;
    this.pageModel.SearchInput = "";
    this.pageModel.SortParameter = this.sortFields[3];
    this.pageModel.SortReversed = true;
    this.pageModel.Month = null;
    this.pageModel.Year = null;
    this.pageModel.SelectedFilterOptions = {Sender: [], Recipiant: [], FileType: []};
    this.pageModel.FromDateTime = new Date( new Date().getFullYear(), new Date().getMonth() + 1, 1 );
    this.pageModel.FromDateTime.setFullYear(this.pageModel.FromDateTime.getFullYear() - 1);
    this.pageModel.ToDateTime = new Date();
    this.years = [{value: 0, viewValue: 'Alle'}];
    this.getYearsForDropDownList();


  }

  setSelectedFileOptions(data : string[])
  {
    this.pageModel.SelectedFilterOptions.FileType = data;
    this.getEmailForPage();
  }

  setSelectedSenderOptions(data : string[])
  {
    this.pageModel.SelectedFilterOptions.Sender = data;
    this.getEmailForPage();
  }

  setSelectedRecipiantOptions(data : string[])
  {
    this.pageModel.SelectedFilterOptions.Recipiant = data;
    this.getEmailForPage();
  }

  getAvailableFilters()
  {
    if(this.getFileTypeOptionsSub != null)
      this.getFileTypeOptionsSub.unsubscribe();
    if(this.getSenderOptionsSub != null)
      this.getSenderOptionsSub.unsubscribe();
    if(this.getRecipientOptionsSub != null)
      this.getRecipientOptionsSub.unsubscribe();

    let filterOptionPageModel : PaginateModel;

    filterOptionPageModel = JSON.parse(JSON.stringify(this.pageModel));
    filterOptionPageModel.SelectedFilterOptions.FileType = null;
    filterOptionPageModel.PageSize = 9999999;
    this.getFileTypeOptionsSub = this.collabService.GetCollabItemOptions("FileType", filterOptionPageModel).subscribe(availableFilters => {
      /* Find and set all available filters that are selected */
      this.availableOptions_FileType = this.checkSelectedAvailableFilters(availableFilters, this.pageModel.SelectedFilterOptions.FileType);
      /* Update the list if selected filter ids, based on what filters are available */
      this.pageModel.SelectedFilterOptions.FileType = this.availableOptions_FileType.filter(x => x.Check).map(({ FilterID }) => FilterID);
      this.getFileTypeOptionsSub.unsubscribe();
    });

    filterOptionPageModel = JSON.parse(JSON.stringify(this.pageModel));
    filterOptionPageModel.SelectedFilterOptions.Sender = null;
    filterOptionPageModel.PageSize = 9999999;
    this.getSenderOptionsSub = this.collabService.GetCollabItemOptions("Sender", filterOptionPageModel).subscribe(availableFilters => {
      /* Find and set all available filters that are selected */
      this.availableOptions_Sender = this.checkSelectedAvailableFilters(availableFilters, this.pageModel.SelectedFilterOptions.Sender);
      /* Update the list if selected filter ids, based on what filters are available */
      this.pageModel.SelectedFilterOptions.Sender = this.availableOptions_Sender.filter(x => x.Check).map(({ FilterID }) => FilterID);
      this.getSenderOptionsSub.unsubscribe();
    });

    filterOptionPageModel = JSON.parse(JSON.stringify(this.pageModel));
    filterOptionPageModel.SelectedFilterOptions.Recipiant = null;
    filterOptionPageModel.PageSize = 9999999;
    this.getRecipientOptionsSub = this.collabService.GetCollabItemOptions("Recipiant", filterOptionPageModel).subscribe(availableFilters => {
      /* Find and set all available filters that are selected */
      this.availableOptions_Recipiant = this.checkSelectedAvailableFilters(availableFilters, this.pageModel.SelectedFilterOptions.Recipiant);
      /* Update the list if selected filter ids, based on what filters are available */
      this.pageModel.SelectedFilterOptions.Recipiant = this.availableOptions_Recipiant.filter(x => x.Check).map(({ FilterID }) => FilterID);
      this.getRecipientOptionsSub.unsubscribe();
    });
  }

  /***
   * Sets the Check property to true on all available filters that is selected.
   * @param availableFilters The list of available filters.
   * @param selectedFilterIDs The list of all selected filter ids.
   */
  checkSelectedAvailableFilters(availableFilters : CollabItemOption[], selectedFilterIDs : string[]) : CollabItemOption[]
  {
    availableFilters.forEach(x => {
      let optionIfSelected = selectedFilterIDs.find(y => y == x.FilterID);
      if (optionIfSelected != undefined) {
        x.Check = true;
      }
    });
    return availableFilters;
  }

  getYearsForDropDownList() {
    this.years = [{value: 0, viewValue: 'Alle'}];

    this.collabService.GetYearRange(this.pageModel).subscribe(years => {
      console.log(years);
      for (let i = 0; i < years.length; i++) {
        const year = {value: years[i].Year, viewValue: '' + years[i].Year};
        this.years.push(year);
      }

      this.evaluateSelectedYear();
    });

    /*if (!this.themeIdFromRoute ) {
      // for all items
      this.collabService.GetYearRange(this.pageModel).subscribe(years => {
        for (let i = 0; i < years.length; i++) {
          const year = {value: years[i].Year, viewValue: '' + years[i].Year};
          this.years.push(year);
        }

        this.evaluateSelectedYear();
      });
    } else {
      // for theme items
      this.themeService.getDistinctYearsForApprovedItemsInTheme(this.themeIdFromRoute, this.pageModel.SearchInput).subscribe(years => {
        for (let i = 0; i < years.length; i++) {
          const year = {value: years[i], viewValue: '' + years[i]};
          this.years.push(year);
        }
        this.evaluateSelectedYear();
      });
    }*/
  }

  setChartData(chartData : CollabItemMonthCount[]) {
    this.lastReceivedCartData = chartData;
    let dummyData = Object.assign(this.barChartData);
    this.barChartData = [{
      data: dummyData[0].data,
      label: '',
      backgroundColor: []
    }];
    setTimeout(() => {
      if (this.selectedYear == 0 || this.selectedYear == undefined) {
        for (let i = 0; i < 12; i++) {
          this.barChartLabels[i] = (this.barLabelDynamic[i]);
          this.barMonthValue[i] = (this.barLabelValueDynamic[i]);

          let month : CollabItemMonthCount = chartData.find(x => x.Month == this.barMonthValue[i]);
          if(month != undefined)
          {
            this.barChartData[0].data[i] = month.Count;
          }
          else{
            this.barChartData[0].data[i] = 0;
          }
        }
      }
      else {
        for (let i = 0; i < 12; i++) {
          this.barChartLabels[i] = (this.barLabelYear[i]);
          this.barMonthValue[i] = (this.barLabelValueYear[i]);

          let month : CollabItemMonthCount = chartData.find(x => x.Month == this.barMonthValue[i]);
          if(month != undefined)
          {
            this.barChartData[0].data[i] = month.Count;
          }
          else{
            this.barChartData[0].data[i] = 0;
          }
        }
      }
      this.barChartData = dummyData;


      /*for(let i = 0; i < this.barMonthValue.length; i++)
      {
        //barMonthValue

        let month : CollabItemMonthCount = chartData.find(x => x.Month == this.barMonthValue[i]);
        if(month != undefined)
        {
          this.barChartData[0].data[i] = month.Count;
        }
        else{
          this.barChartData[0].data[i] = 0;
        }
      }*/

    }, 50);
  }

  doNothing() {
  }

  addCriteriaToTheme(criteria: SearchCriteria) {
    this.isLoadingData = true;
    this.pageModel.PageIndex = 1;
    this.pageModel.Theme = {ID: this.routeParams['themeId']} as Theme;

    this.themeCriteriaService.create(this.theme.ID, criteria).subscribe(() =>
      this.getYearsForDropDownList()
    );
  }

  // NM 060220 - addEntityToTheme()
  includeEntityInTheme(criteria: SearchCriteria)
  {
    let theme = criteria.Themes[0];
    criteria.Themes = null;
    console.log(criteria.IsDefault);
    if(!!theme)
    {
    this.themeCriteriaService.includeEntityInTheme(theme.ID, criteria).subscribe(() => {
      this.snackBar.open("Record tilføjes til tema: " + theme.Title, "OK", {
        duration: 3000
      });
    });
    }
  }

  // NM 070220 - addEntityToTheme()
  excludeEntityFromTheme(criteria: SearchCriteria)
  {
    let theme = criteria.Themes[0];
    criteria.Themes = null;
    console.log(criteria.IsDefault);
    if(!!theme)
    {
      this.themeCriteriaService.excludeEntityInTheme(theme.ID, criteria).subscribe(() => {
        this.snackBar.open("Record ekskluderet fra tema: " + theme.Title, "OK", {
          duration: 3000
        });
        this.getYearsForDropDownList();
      });
    }
  }


  getUrlOfServiceFile(id: number) {
    this.attachmentService.getServiceFileUrl(id).subscribe(value => {
      this.fileUrl = value;
    });
  }

  removeCriteriaFromTheme(criteria: SearchCriteria) {

    this.isLoadingData = true;
    this.pageModel.PageIndex = 1;
    //this.pageModel.Theme = {ID: this.pageModel.ThemeId } as Theme;

    this.themeCriteriaService.delete(this.theme.ID, criteria).subscribe(() => {
      this.snackBar.open("Record slettet fra tema", "OK", {
        duration: 3000
      });
      this.getYearsForDropDownList()
    });
  }

  setSelectedMonthDirectly(selectedMonth: number) {
    if(selectedMonth == null)
    {
      this.pageModel.Month = 0;
      this.pageModel.Year = this.selectedYear;
    }
    else
    {
      this.pageModel.Month = this.barMonthValue[selectedMonth];
      let collabItemMonth = this.lastReceivedCartData.find(x => x.Month == this.pageModel.Month);
      this.pageModel.Year = collabItemMonth != undefined ? collabItemMonth.Year : 0;

    }
  }

  setSearchInput(searchInput : string)
  {
    this.pageModel.SearchInput = searchInput;
    this.getYearsForDropDownList();
  }

  getEmailForPage(isRejected?) {
    if(this.getDataForPageSub != null)
      this.getDataForPageSub.unsubscribe();

    this.pageModel.UserID = +this.loginService.getLoggedInUserID();


    if (this.isModal) {
      this.isLoadingData = true;
      this.pageModel.Default = true;
      this.pageModel.isRejected = this.isRejected;

    }
    if(this.pageModel.ThemeId) {
      this.pageModel.Theme = {ID: this.pageModel.ThemeId} as Theme;
    }
    /* this.getAvailableFilters();
     this.getDataForPageSub = this.themeService.getThemeWithChart(this.pageModel).subscribe(pModel => {
         (this.theme = pModel.Theme);
         (this.approvedItems = pModel.ApprovedItems != null ? pModel.ApprovedItems : []);
         (this.emailsCount = pModel.Total);
         (this.attachmentCount = pModel.TotalAttachments);
         (this.isLoadingData = false);
         this.getDataForPageSub.unsubscribe();
       });*/
 /*   }
    else {*/
      console.log("Need theme id!: " + this.pageModel.ThemeId);
      this.isLoadingData = true;
      this.getAvailableFilters();
      this.getDataForPageSub = this.collabService.GetCollabItems(this.pageModel).subscribe(model => {
        if(model.Theme) {
          this.theme = model.Theme,
            this.isSubscribed = model.Theme.UserThemes[0].isSubscribed,
            this.isAdmin = model.Theme.UserThemes[0].hasCreated;
          (this.title = this.theme.Title);
        }
        (this.approvedItems = model.ApprovedItems),
          (this.emailsCount = model.Total),
          (this.attachmentCount = model.TotalAttachments),
          (this.isLoadingData = false);
        this.reloadBarChart(true);
        this.getDataForPageSub.unsubscribe();
      })
    /*};*/
    /*else if (this.themeIdFromRoute  && !this.isModal) {
      this.isLoadingData = true;
      this.pageModel.Theme = {ID: this.themeIdFromRoute } as Theme;
      this.themeService.getThemeWithChart(this.pageModel).subscribe(pModel => {
        (this.theme = pModel.Theme);
        this.isSubscribed = pModel.Theme.UserThemes[0].isSubscribed;
        this.isAdmin = pModel.Theme.UserThemes[0].hasCreated;
        (this.approvedItems = pModel.ApprovedItems != null ? pModel.ApprovedItems : []);
        (this.emailsCount = pModel.Total);
        (this.attachmentCount = pModel.TotalAttachments);
        (this.title = this.theme.Title);
        (this.isLoadingData = false);
        this.reloadBarChart(true);
      });
    }
    else if (!this.isModal){
      this.isLoadingData = true;
      this.collabService.GetCollabItems(this.pageModel).subscribe(model => {
          (this.approvedItems = model.ApprovedItems),
          (this.emailsCount = model.Total),
          (this.attachmentCount = model.TotalAttachments),
          (this.isLoadingData = false);
        this.reloadBarChart(true);
      });*/

  }

  reloadBarChart(forTheme: boolean) {

    console.log("Reloading chart");
    this.collabService.GetCollabItemCountPrMonth(this.pageModel)
      .subscribe(chartData => {
        console.log(chartData);
        this.setChartData(chartData);
      });
    /*if (!forTheme) {
      this.collabService.GetCollabItemCountPrMonth(this.pageModel)
        .subscribe(chartData => {
        chartData[0].Count !== this.barChartData[0].data[0] ? this.setChartData(chartData) : this.doNothing();
      });
    } else {
      this.collabService.GetCollabItemCountPrMonth(this.pageModel)
        .subscribe(chartData => {
          chartData[0].Count !== this.barChartData[0].data[0] ? this.setChartData(chartData) : this.doNothing();
        });
    }*/
  }

  subscribeToTheme() {
    this.subscribeService
      .addSubscription(
        this.theme,
        Number(this.loginService.getLoggedInUserID())
      )
      .subscribe(model => {
        this.snackBar.open(
          'Du er nu subscribed til ' + this.theme.Title,
          'Ok',
          {
            duration: 3000
          }
        ),
          (this.isSubscribed = model);
      });
  }

  unsubscribeFromTheme() {
    this.subscribeService
      .deleteSubscription(
        this.theme,
        Number(this.loginService.getLoggedInUserID())
      )
      .subscribe(model => {
        this.snackBar.open(
          'Du er nu unsubscribed til ' + this.theme.Title,
          'Ok',
          {
            duration: 3000
          }
        ),
          (this.isSubscribed = model);
      });
  }

  rejectEmail(email : Email)
  {
    debugger;
    this.emailService.transferDisapprovedEmail(email).subscribe(() => {console.log("email with ID deleted:" + email.ID); this.resetDetailViews(); this.getYearsForDropDownList();}  );
  }

  rejectFile(file : ApprovedAttachment)
  {
    this.fileService.transferDisapprovedFile(file).subscribe(() => {this.resetDetailViews(); this.getYearsForDropDownList();})
  }

  setCurrentPage(currentPage: number) {
    this.pageModel.PageIndex = currentPage;
  }

  setPageSize(pageSize: number) {
    this.pageModel.PageSize = pageSize;
  }

  showDetailsOfApprovedItem(item: ApprovedItem) {
    this.view = 1;
    if (item.IsMail) {
      this.emailService.get('' + item.ID).subscribe(email => {
        this.detailedEmail = email;
        this.detailedFile = null;
        console.log(this.detailedEmail);
      });
    } else {
      this.fileHistoryService.getAllForFile(item.ID).subscribe(fileHistories => {
        this.fileHistories = fileHistories
      });
      this.attachmentService.get('' + item.ID).subscribe(file => {
        this.downloadURL = this.attachmentService.returnDownloadURL(item.ID);
        this.detailedFile = file;
        this.detailedEmail = null;
        console.log(this.detailedFile);
      });
    }
    this.createClickHistoryItem(item);
  }

  createClickHistoryItem(item: ApprovedItem) {

    let clickHistoryItem: ClickHistory;

    let userId = Number.parseInt(this.loginService.getLoggedInUserID());

    clickHistoryItem =
      ({
        ID: 0,
        EntryId: item.ID,
        LastViewed: new Date(),
        EntryTitle: item.Subject,
        User: {ID: userId},
        EntryType: 'ApprovedFile'
      });
    if (item.IsMail == 1) {
      clickHistoryItem.EntryType = 'ApprovedEmail';
    }
    if (clickHistoryItem.EntryTitle !== '' && clickHistoryItem.EntryType !== '') {
      this.clickHistoryService.createClickHistoryEntry(clickHistoryItem).subscribe(() =>
      {
        this.clickHistoryService.historyWasChanged();
        console.log('Created a HistoryItem From the Frontend called: ' + clickHistoryItem.EntryTitle)
      });
    }
  }

  resetDetailViews() {
    this.detailedEmail = null;
    this.detailedFile = null;
    this.base64Item = null;
    this.pdfToOpenUrl = null;
    this.fileUrl = null;
    this.downloadURL = null;
  }

  setSelectedYearDirectly(year: any) {
    this.pageModel.PageIndex = 1;
    this.selectedYear = year;
    this.pageModel.Year = year;
    this.pageModel.Month = 0;

    if(year != 0)
    {
      console.log("Year something");
      this.pageModel.FromDateTime = new Date(year, 0, 1);
      this.pageModel.ToDateTime = new Date(year, 11, 31);
      console.log(this.pageModel.FromDateTime + "");
      console.log(this.pageModel.ToDateTime + "");
    }
    else{
      this.pageModel.FromDateTime = new Date( new Date().getFullYear(), new Date().getMonth() + 1, 1 );
      this.pageModel.FromDateTime.setFullYear(this.pageModel.FromDateTime.getFullYear() - 1);
      this.pageModel.ToDateTime = new Date();
    }
    console.log(this.pageModel.FromDateTime + "");
    console.log(this.pageModel.ToDateTime + "");

    this.getEmailForPage();
  }

  evaluateSelectedYear() {
    this.pageModel.PageIndex = 1;

    /*if (this.pageModel.Year == null || this.years.findIndex(x => x.value == this.pageModel.Year) == -1) {
      this.pageModel.Year = this.years[0].value;
      this.pageModel.Month = 0;
    }
    else {
      // KEEP THE SELECTED YEAR
    }*/

    //const forTheme = !this.routeParams['themeId'];


    this.getEmailForPage();
    //this.reloadBarChart(!forTheme);
  }

  getBase64Item(id: number) {
    this.attachmentService.getBase64Item(id).subscribe(base64 => {
      this.base64Item = base64;
      });
  }

  getDownloadURLForFile(fileID: number) {
    this.downloadURL = this.attachmentService.returnDownloadURL(fileID);
  }
  downloadCurrentFile() {
    this.downloadGivenFile(this.detailedFile);
  }

  openPdfFile(file) {
    this.attachmentService.downloadFile(file.ID).subscribe(blob => {
        let fileFromBlob = new File([blob], file.FileName, {type:'application/pdf', lastModified: Date.now()});

        this.pdfToOpenUrl = window.URL.createObjectURL(fileFromBlob);
      },
      error => {
        console.log(error);
      });
  }


  downloadGivenFile(file) {
    this.attachmentService.downloadFile(file.ID).subscribe(blob => {

      const fileUrl = URL.createObjectURL(blob);
      // for IE 10+
      const fileNameArray = file.FileName.split('\\');
      const fileName = fileNameArray[fileNameArray.length - 1];

      if (window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(blob, fileName);
      } else {
        const element = document.createElement('a');
        element.href = fileUrl;
        element.setAttribute('download', fileName);
        element.setAttribute('target', '_blank');
        document.body.appendChild(element);
        element.click();
      }
    });
  }

  changeSortParameter(sortParameter: string) {
    this.pageModel.SortParameter = sortParameter;
  }

  changeSortReversed(isReversed: boolean) {
    this.pageModel.SortReversed = isReversed;
  }

  //KG and CSK updating manuel indexing data on email content 280519
  updateManualIndexingDataOnEmail(email: Email) {
    this.view = 1;
    this.emailService.putEmail(email).subscribe(newEmail => {
      this.detailedEmail = newEmail;
    });
  }

    //KG and CSK updating manuel indexing data on email content 280519
    updateManualIndexingDataOnFile(file: ApprovedAttachment) {
      this.view = 1;
      this.fileService.putFile(file).subscribe(newFile => {
        this.detailedFile = newFile;
      });
  }
  // NM 050220 - Add entity to theme >>
  getThemesForPage(themeSearchInput : string) {
    let pagemodel = new ThemePaginateModel();
    pagemodel.UserID = Number(this.loginService.getLoggedInUserID());
    pagemodel.PageIndex = 0;
    pagemodel.PageSize = 10;
    pagemodel.SearchInput = themeSearchInput;
    pagemodel.SortParameter = 'CreatedDate';
    pagemodel.SortReversed = false;

    this.themeService.getThemesForPage(pagemodel).subscribe(model => {
      (this.dropDownThemes = model.Themes)
    });
  }
}
