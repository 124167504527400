import {Component, Input, OnInit} from '@angular/core';
import {EmailAccount} from '../../../Entities/EmailAccount';
import {EmailAccountService} from '../../../services/email-account.service';
import {LoginService} from '../../../services/login.service';
import {UserService} from '../../../services/user.service';
import {ActivatedRoute, Router} from '@angular/router';
import {User, UserAccountType} from '../../../Entities/User';
import {MatSnackBar} from '@angular/material';

@Component({
  selector: 'app-user-account-detail',
  templateUrl: './user-detail.component.html',
  styleUrls: ['./user-detail.component.css']
})
export class UserDetailComponent implements OnInit {
  emailAccounts: EmailAccount[];

  routerLink: any;

  errorMessage : string;

  user : User;

  userIdFromParam : string;

  constructor(private loginService: LoginService,
              private userService: UserService,
              private emailAccountService: EmailAccountService,
              private route: ActivatedRoute,
              private router: Router,
              private snackBar : MatSnackBar
  ) { }

  ngOnInit() {
    this.createAzureActiveAccountUser();
    this.route.params.subscribe(params => {
      if (params["id"]) {
        this.userIdFromParam = params["id"];

      } else {
        this.userIdFromParam = !!this.loginService.getLastVisitedUser() && this.loginService.getLastVisitedUser() != "" ?
          this.loginService.getLastVisitedUser() : this.loginService.getLoggedInUserID();
      }
      this.loginService.setLastVisitedUser(this.userIdFromParam);

      this.getUserById(this.userIdFromParam);
      this.routerLink = '/emailaccount-create/' + (params['id'])
    });
  }


  //get emailaccounts with where user id is x
  getUserById(id: string){
    this.userService.getUserById(id).subscribe(user => {
      this.user = user;
    });


    this.emailAccountService.getByUserID(id).subscribe(us => {
      us.toString();
      this.emailAccounts = us;
    });
  }

  saveUser(account: User) {
    debugger;
    this.userService.updateUser(account)
      .subscribe(() => {
          this.loginService.changePassword(account).subscribe(() => {
              this.snackBar.open("Adgangskoden er opdateret.", "OK", {
                duration: 3000
              });
              this.errorMessage = null;
            },
            err => (this.errorMessage =
              'Adgangskoden kunne ikke opdateres. Enten er den indtastede adgangskode forkert, eller er den nye for svag. \nPrøv venligst igen. '))
        },
        err =>
          (this.errorMessage =
            'Brugeren kunne ikke findes i systemet. ')
      ),
      err => (this.errorMessage =
        'Brugeren kunne ikke findes i systemet. ');

  }


  deleteEmailAccount(emailAccount: EmailAccount) {
    this.getUserById(this.userIdFromParam);
     this.emailAccountService.delete(emailAccount).subscribe(() => {
       this.ngOnInit();
     });
  }

  updateIsExportingAttProperty(account: EmailAccount) {
    this.emailAccountService.updateIsExportingStatus(account).subscribe(() => {
      console.log('exporting property was changed');
    });
  }

  createAzureActiveAccountUser() {
    let userID;
    this.route.queryParams.subscribe(params => {
      let code = params['code'];
      let state = params['state'];
      userID = +state;
      if (!!code) {
        this.user = new User();
        this.user.AccountType = UserAccountType.AzureADAccount;
        this.user.Password = code;
        if(userID > 0) {
          this.user.ID = userID;
          this.userService.updateUser(this.user).subscribe(() => {
            this.snackBar.open("Adgangskoden er opdateret.", "OK", {
              duration: 3000
            });
          });
          window.history.replaceState({},'',location.origin + '/user-detail/'+ userID);
        }
      }
    });
  }
}
